import { useQuery } from "@vue/apollo-composable";
import { DataWarehousePropertiesDocument } from "~/graphql/generated/graphql";
export var useDatasourcesPropertiesQuery = function useDatasourcesPropertiesQuery(variables) {
  var _useQuery = useQuery(DataWarehousePropertiesDocument, variables),
    result = _useQuery.result,
    loading = _useQuery.loading,
    refetch = _useQuery.refetch;
  var properties = computed(function () {
    var _result$value$assetMa, _result$value, _result$value$assetMa2;
    return (_result$value$assetMa = result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$assetMa2 = _result$value.assetManagementProperties) === null || _result$value$assetMa2 === void 0 ? void 0 : _result$value$assetMa2.items) !== null && _result$value$assetMa !== void 0 ? _result$value$assetMa : [];
  });
  return {
    loading: loading,
    properties: properties,
    refetch: refetch
  };
};