import "core-js/modules/es6.array.filter.js";
import { useQuery } from "@vue/apollo-composable";
import { DataWarehousePropertiesCountDocument } from "~/graphql/generated/graphql";
export var useDatasourcesPropertiesCountQuery = function useDatasourcesPropertiesCountQuery() {
  var _useQuery = useQuery(DataWarehousePropertiesCountDocument),
    result = _useQuery.result,
    loading = _useQuery.loading;
  var totalCount = computed(function () {
    var _result$value$assetMa, _result$value, _result$value$assetMa2, _result$value$assetMa3;
    return (_result$value$assetMa = result === null || result === void 0 ? void 0 : (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$assetMa2 = _result$value.assetManagementProperties) === null || _result$value$assetMa2 === void 0 ? void 0 : (_result$value$assetMa3 = _result$value$assetMa2.items) === null || _result$value$assetMa3 === void 0 ? void 0 : _result$value$assetMa3.length) !== null && _result$value$assetMa !== void 0 ? _result$value$assetMa : 0;
  });
  var mappedCount = computed(function () {
    var _result$value$assetMa4, _result$value2, _result$value2$assetM, _result$value2$assetM2;
    return (_result$value$assetMa4 = result === null || result === void 0 ? void 0 : (_result$value2 = result.value) === null || _result$value2 === void 0 ? void 0 : (_result$value2$assetM = _result$value2.assetManagementProperties) === null || _result$value2$assetM === void 0 ? void 0 : (_result$value2$assetM2 = _result$value2$assetM.items) === null || _result$value2$assetM2 === void 0 ? void 0 : _result$value2$assetM2.filter(function (item) {
      return (item === null || item === void 0 ? void 0 : item.portfolioId) != null;
    }).length) !== null && _result$value$assetMa4 !== void 0 ? _result$value$assetMa4 : 0;
  });
  return {
    loading: loading,
    totalCount: totalCount,
    mappedCount: mappedCount
  };
};